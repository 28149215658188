<template>
    <div id="BigDataPageThree">
        <div class="data-page-content rowFlex" style="justify-content: center;">
            <div class="data-item-wrap">
                <div v-for="(item,index) in robotInfor.slice(0,2)" :key="item.id" class="data-item-wrap-con">
                    <div class="bg" v-if="powerOn">
                        <div class="tageText">
                            <div class="teg">{{`${item.deviceName}`}}</div>-第一视角
                        </div>
                        <div class="drone-video">
                            <div class="ali-player" :id="`vido_${index}`" />
                        </div>
                        <div  v-if="item.isDisconnected" class="disconnected">
                            {{item.showTitle}}
                        </div>
                        <div class="robotInfo">
                            <div class="electricQuantity">
                                <span>机器人当前电量：{{item.percentage?item.percentage:'-'}}%</span>
                                <!-- <div class="robotState">{{ROBOT_STATE[item.state]}}</div> -->
                                <template v-if="ROBOT_STATE[item.state]">
                                    <div class="state">{{ROBOT_STATE[item.state]}}</div>
                                 </template>
                                <template v-else>
                                    <div class="state">{{robotStateName}}</div>
                                </template>
                            </div>
                            <p>速度：{{item.speed}}米/秒</p>
                            <p>{{getDate}}</p>
                        </div>
                        <div class="photograph">
                            <img v-for="item in item.imageList" :key="item" :src="item" alt="">
                        </div>
                    </div>
                    <div
                    v-else
                    class="bg"
                        style="
                            font-size: 40px;
                            color: #00fff4;
                            text-align: center;
                            line-height:450px
                        "
                        >
                        暂无画面
                    </div>
                </div>
                 <div v-if = 'robotInfor.length < 1'
                    class="bg"
                        style="
                            font-size: 40px;
                            color: #00fff4;
                            text-align: center;
                            line-height:450px;
                            margin-right:50px
                        "
                        >
                      暂无画面
                </div>
                <div v-if = 'robotInfor.length < 2'
                    class="bg"
                        style="
                            font-size: 40px;
                            color: #00fff4;
                            text-align: center;
                            line-height:450px;
                        "
                        >
                       暂无画面
                </div>
            </div>
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel patrolRecord">
                    <div class="data-item-title">近五次巡视记录</div>
                    <div class="swiper_header">
                        <div style="width: 142px;">
                            <p>开始时间</p>
                        </div>
                        <div style="width: 90px;">
                            <p>返回时间</p>
                        </div>
                        <div style="width: 104px;">
                            <p>机器人</p>
                        </div>
                        <div style="width: 110px;">
                            <p>巡视任务</p>
                        </div>
                        <div style="width: 80px;">
                            <p>巡视点</p>
                        </div>
                    </div>
                    <div class="swiper-pass-log">
                        <div class="swiper-pass-log-wrapper">
                            <div v-for="item in robotTask" :key="item.id"
                                 :class="['swiper-slide swiper-no-swiping']"
                            >
                                <ul>
                                    <li>{{item.startTime}}</li>
                                    <li>{{item.endTime}}</li>
                                    <li>{{item.deviceName}}</li>
                                    <li style="width:20%; text-align: center">{{item.taskName}}</li>
                                    <li>{{item.patrolPointNum}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-page-content rowFlex" style="justify-content: center;">
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel patrolRoute">
                    <div class="data-item-title">巡视路线</div>
                    <div class="patrolRouteImg">
                        <div class="patrolRouteImg_item" v-for="(item,index) in robotInfor.slice(0,6)" :key="index+'aaq'">
                            <span>{{item.deviceName}}</span>
                            <template v-if="item.mapDesc">
                                <RobotTaskList :item="{...item,id:index}"></RobotTaskList>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel console">
                    <div class="data-item-title">控制台</div>
                    <div class="console_Info">
                        <div class="first_col_roBot">
                            <div class="roBot_item" v-for="(item,index) in robotInfor.slice(0,3)" :key="index + 'left'">
                                <div class="roBot_item_name">
                                    <div class="name">{{item.deviceName}}</div>
                                    <!-- <div class="state">{{ROBOT_STATE[item.state]}}</div> -->
                                    <template v-if="ROBOT_STATE[item.state]">
                                         <div class="state" v-if="!item.updateStatus">{{ROBOT_STATE[item.state]}}</div>
                                         <div class="state" v-else>{{`${item.second}s后自启`}}</div>
                                    </template>
                                   <template v-else>
                                       <div class="state">{{robotStateName}}</div>
                                   </template>
                                </div>
                                <div>
                                    机器人当前电量：{{item.percentage?item.percentage:'-'}}%
                                </div>
                                <div>
                                    巡视任务：{{item.taskName?item.taskName:'-'}}
                                </div>
                                <div class="returnRobot"  v-if="item.state == 1 && !item.courseReversal" @click="robotReturn(item.deviceId)">
                                    返回
                                </div>
                            </div>
                        </div>
                        <div class="first_col_roBot" v-if="robotInfor.length < 1">
                            <div  style="width:224px;height:102px;padding:10px 20px">
                            </div>
                        </div>

                        <div class="left_line">
                            <div class="line_first"></div>
                            <div class="line_second"></div>
                            <div class="line_third"></div>
                        </div>
                        <div class="center">
                            <div class="hollow_circle" v-if='robotInfor.length > 0'>
                                <div class="circle">
                                    <div :class="robotStateNameBtn !== '启动'?'texts':'text'" @click="startUpBtn" >{{robotStateNameBtn}}</div>
                                </div>
                            </div>
                            <div v-else class="hollow_circles">
                                 <div class="circle">
                                      <div class="textj" >{{'加载中'}}</div>
                                 </div>
                            </div>

                        </div>
                        <div class="right_line">
                            <div class="line_first"></div>
                            <div class="line_second"></div>
                            <div class="line_third"></div>
                        </div>
                        <div class="first_col_roBot">
                            <div class="roBot_item" v-for="(item,index) in robotInfor.slice(3,6)" :key="index + 'right'">
                                 <div class="roBot_item_name">
                                    <div class="name">{{item.deviceName}}</div>
                                    <template v-if="ROBOT_STATE[item.state]">
                                         <div class="state" v-if="!item.updateStatus">{{ROBOT_STATE[item.state]}}</div>
                                         <div class="state" v-else>{{`${item.second}s后自启`}}</div>
                                    </template>
                                   <template v-else>
                                       <div class="state">{{robotStateName}}</div>
                                   </template>
                                </div>
                                <div>
                                    机器人当前电量：{{item.percentage?item.percentage:'-'}}%
                                </div>
                                <div>
                                    巡视任务：{{item.taskName?item.taskName:'-'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
import Swiper from 'swiper';
import dayjs from "dayjs";
import 'swiper/swiper.min.css';
import {robotDeviceUrl, queryRobotDeviceMapUrl,ueryInspectionTasksUrl,terminationReturnUrl} from "@/requestUrl";
import RobotTaskList from '@/components/robotTaskList.vue';
import {ROBOT_STATE} from "@/constant";
import { initWs } from "@/views/BigData/tools";
import OSS from "ali-oss";
//判断浏览器类型
const isEdge = window.navigator.userAgent.toLocaleUpperCase().includes("EDG");
const ROBOT_STATE_BTN = {
    0:'加载中',
    1:'未连接',
    2:'启动',
    3:'进行中',
    4:'充电中'
};
export default {
    name: "BigDataPageThree",
    components: {
        DataItemPanelLeftBorder,
        RobotTaskList
    },
    data() {
        return {
            robotStateName:'未连接',
            robotInfor:[],//状态信息
            ROBOT_STATE,
            ROBOT_STATE_BTN,
            robotTask:[],//任务
            socket:null,
            //临时存储
            stsTokenStorage:{},
            displayRobotOne:0,
            displayRobotTwo:1,
            powerOn:false,
            getDate:null,
            getDateTime:null,
            pollingTimer:null,
            robotStateNameBtn:''
        }
    },
    beforeDestroy() {
        if (this.socket) {
        this.socket.close(3000, "页面关闭");
        }
        clearInterval(this.pollingTimer);
        clearInterval(this.getDateTime);
    },
    mounted(){
        //时间
        this.currentTime();
        //查询状态信息
        this.queryRobotDeviceMap();
        //查询任务
        this.ueryInspectionTasks();
        //链接socked
        this.socketLink();
        //轮询获取状态信息
        this.pollingGetStatus();
    },
    methods: {
        //机器人范航
        robotReturn(id){
            this.$fly.get(terminationReturnUrl,{
                id
            }).then(res => {

            })
        },
        //返回机器人的按钮状态
        robotStateBtn(data){
            let inforArr = data.map(item => {
                return item.state
            });
            if(inforArr.indexOf(0) != -1){
                this.robotStateNameBtn = '启动'
            }
            else if(inforArr.indexOf(1) != -1){
                this.robotStateNameBtn = '进行中'
            }else if(inforArr.indexOf(3) != -1){
                 this.robotStateNameBtn = '充电中'
            }else{
                this.robotStateNameBtn = '未连接'
            }
            if(inforArr.indexOf(3) != -1){
                for(var i = 0;i<data.length;i++){
                    if(inforArr.indexOf(3) != -1 && data[i].bootable){
                        this.robotStateNameBtn = '启动'
                        return
                    }
                }
            }
            for(var i = 0;i<data.length;i++){
                if(data[i].updateStatus && data[i].state == 0){
                    this.robotStateNameBtn = '进行中';
                }else if(!data[i].updateStatus && data[i].state == 0){
                    this.robotStateNameBtn = '启动';
                }
            }
        },
        //时间
        currentTime(){
            var x = new Date();
            var month = x.getMonth() + 1 < 10?`0${x.getMonth()+1}`:x.getMonth()+1;
            var getDate  = x.getDate()<10?`0${x.getDate()}`:x.getDate();
            var hours = x.getHours()<10?`0${x.getHours()}`:x.getHours();
            var minutes = x.getUTCMinutes()<10? `0${x.getUTCMinutes()}`:x.getUTCMinutes();
            this.getDate = `${month}-${getDate} ${hours}:${ minutes}`;
            clearInterval(this.getDateTime);
            this.getDateTime = setInterval(() =>{
                x = new Date();
                month = x.getMonth() + 1 < 10?`0${x.getMonth()+1}`:x.getMonth()+1;
                getDate  = x.getDate() < 10?`0${x.getDate()}`:x.getDate();
                hours = x.getHours() < 10?`0${x.getHours()}`:x.getHours();
                minutes = x.getUTCMinutes() < 10? `0${x.getUTCMinutes()}`:x.getUTCMinutes();
                this.getDate = `${month}-${getDate} ${hours}:${ minutes}`;
            },30000)
        },
        initSwiper(elName, options = {}) {
            const tempSwiper = new Swiper(elName, options);
            return tempSwiper;
        },
        //机器人控制台以及巡视路线信息
        queryRobotDeviceMap(){
            this.$fly.get(`${queryRobotDeviceMapUrl}?regionCode=${this.$vc.getCurrentRegion().code}`).then(res => {
                if(res.code != 0){
                    return
                }
                this.robotInfor = res.data.sort((a,b) => {
                    return b.id - a.id;
                });

                //其他状态
                let endRobot = this.robotInfor.filter(item => {
                    return (item.state != 0  && item.state != 2 && item.state != 3 && item.state != 1 && item.state != null)
                })

                //未连接
                let weiArr =  this.robotInfor.filter(item => {
                    return item.state == null;
                })
                //正在对接充电桩
                let zhuangArr =  this.robotInfor.filter(item => {
                    return item.state == 2;
                })
                //充电中状态
                let chongArr = this.robotInfor.filter(item => {
                    return item.state == 3;
                })
                //空闲状态
                let startRobot = this.robotInfor.filter(item => {
                    return item.state == 0;
                })
                let jinArr  = this.robotInfor.filter(item => {
                    return item.state == 1;
                })

                this.robotInfor = jinArr.concat(startRobot.concat(chongArr.concat(zhuangArr.concat(endRobot.concat(weiArr)))));
                this.robotStateBtn(this.robotInfor);
                for(var i =0;i<this.robotInfor.length;i++){
                    if(this.robotInfor[i].state == 1){
                        this.powerOn = true;
                        this.$nextTick(() => {
                            this.queryRotorStr(this.robotInfor);
                        });
                        return;
                    }
                }
            })
        },
        //机器人近五次任务
        ueryInspectionTasks(){
            this.$fly.get(`${ueryInspectionTasksUrl}?regionCode=${this.$vc.getCurrentRegion().code}`).then(res => {
                if(res.code != 0){
                    return
                }
                this.robotTask = res.data;
            });
        },
        //启动机器人
        startUpBtn(){
            if(this.robotStateNameBtn != '启动'){
                return
            }
           var paramiseAll = [];
           this.robotInfor.forEach(item => {
                if(item.state != null){
                this.robotStateNameBtn = '启动中';
                paramiseAll.push(this.$fly.get(`${robotDeviceUrl}${item.taskId}`));
                }

            })
            if(paramiseAll.length == 0){
                return
            }
            Promise.all(paramiseAll).then(async res => {

            })
        },
        //链接Socket
         socketLink(){
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
            this.socket = initWs({
                messageHandler:res => {
                    if(!res.data){
                        return
                    }
                    var newDate = JSON.parse(res.data);
                    try{
                        const dataTypeHandlers ={
                            //机器人状态信息
                            robotInfo:() => {
                                for(var i = 0;i<this.robotInfor.length;i++){
                                    if(this.robotInfor[i].deviceId == newDate.data.id){
                                        this.robotInfor[i].percentage = newDate.data.percentage;
                                        this.robotInfor[i].location = newDate.data.location;
                                        this.robotInfor[i].state = newDate.data.state;
                                        this.robotInfor[i].speed = newDate.data.speed;
                                        this.robotInfor[i].bootable = newDate.data.bootable;
                                        this.robotInfor[i].courseReversal = newDate.data.courseReversal;
                                        this.robotStateBtn(this.robotInfor);
                                        this.$vc.emit('locationRobot'+this.robotInfor[i].deviceId,this.robotInfor[i].location);
                                    }
                                }
                            },
                            //机器人截图路径
                            robotShootPicture:async () =>{
                                for(var i = 0;i<this.robotInfor.length;i++){
                                    if(this.robotInfor[i].deviceId == newDate.data.id){
                                        let imageList = this.robotInfor[i].imageList;
                                        if(!imageList){
                                            imageList = [];
                                        }
                                        // const client = await this.createOss(
                                        //     "oss-cn-beijing",
                                        //     "cosmoss-drones"
                                        // )``
                                        // const pictureUrl = client.signatureUrl(newDate.data.picture);
                                        imageList.unshift(`https://cosmoss-robot.oss-cn-beijing.aliyuncs.com/${newDate.data.picture}`);
                                        if (imageList.length > 5) {
                                            imageList.pop();
                                        }
                                        this.$set(this.robotInfor[i],'imageList',imageList);
                                    }
                                }
                            },
                            //机器人图片文件夹路径
                            robotImageUrl:async () => {
                                this.powerOn = true;
                                this.$nextTick(() => {
                                     this.queryRotorStr(this.robotInfor);
                                })
                            },
                            //机器人巡航任务
                            robotTaskLog:() =>{
                                this.robotTask = newDate.data;
                            },
                            //设备xx秒后自启动
                            canStart:() =>{
                                //间隔30s启动
                                this.setRobotSetTimer(newDate.data);

                            },
                            //设备断开连接后 开始重连
                            startConnection:() =>{
                                this.disconnected(newDate.data.id);
                            },
                            //设备重连成功/失败
                            isConnection:() => {
                                this.reconnectionResult(newDate.data);
                            }
                        }
                        if (dataTypeHandlers.hasOwnProperty(newDate.method)) {
                            dataTypeHandlers[newDate.method]();
                        }
                    }
                    catch{
                        console.log("解析失败");
                    }

                }
            });
        },
        //重连结果
        reconnectionResult(coneId){
            for(var i = 0;i < this.robotInfor.length;i++){
                if(i < 2){
                    console.log(coneId);
                    if(coneId.id == this.robotInfor[i].deviceId && coneId.state){
                       this.$set(this.robotInfor[i],'isDisconnected',false);
                    }
                    if(coneId.id == this.robotInfor[i].deviceId &&!coneId.state){
                        this.$set(this.robotInfor[i],'showTitle','重连失败，请去寻找机器人');
                    }
                }
            }
        },
        //设备断开连接后 开始重连
        disconnected(coneId){
            for(var i = 0;i < this.robotInfor.length;i++){
                if(i < 2){
                    if(coneId == this.robotInfor[i].deviceId){
                     this.$set(this.robotInfor[i],'showTitle','已断开，正在重连...') ;
                       this.$set(this.robotInfor[i],'isDisconnected',true)
                    }

                }
            }
        },
        //设置机器人的启动时间
        setRobotSetTimer(canItem){
             this.robotInfor.forEach((item,index) => {
                 if(item.deviceId == canItem.id){
                     this.$set(this.robotInfor[index],'updateStatus',true);
                     clearInterval(this.robotInfor[index].setInter);
                     this.$set(this.robotInfor[index],'second',canItem.second);
                     this.robotInfor[index].setInter = setInterval(() =>{
                         this.robotInfor[index].second--;
                         if(this.robotInfor[index].second <= 0){
                            this.$set(this.robotInfor[index],'updateStatus',false);
                             clearInterval(this.robotInfor[index].setInter);
                         }
                     },1000)
                 }
             })
        },
        //查询机器人拉流
        async queryRotorStr(data){
            this.robotStateBtn(this.robotInfor);
            this.robotInfor = data.sort((a,b) => {
                    return b.id - a.id;
            });
            let endRobot = this.robotInfor.filter(item => {
                return item.state != 1;
            })
            let startRobot = this.robotInfor.filter(item => {
               return item.state == 1;
            })
            this.robotInfor = startRobot.concat(endRobot);
            data = this.robotInfor.slice(0,2);
            data.forEach((items,index) => {
                if(items.state == 0 ||items.state == 1 ){
                    const { rtsPullStreamAddr: liveUrl, flvPullStreamAddr: flvUrl } =items;
                    setTimeout(() =>{
                            //阿里云播放器
                            new Aliplayer(
                            {
                            id: `vido_${index}`,
                            source: isEdge ? flvUrl : liveUrl,
                            width: "520px",
                            height: "360px",
                            autoplay: true,
                            isLive: true,
                            rePlay: false,
                            playsinline: true,
                            preload: true,
                            controlBarVisibility: "never",
                            useH5Prism: true,
                            skinLayout: [{ name: "bigPlayButton", align: "cc" }],
                            },
                            function (player) {
                                // player.play();
                                if (player) {
                                    player.setVolume(0);
                                    console.log(
                                    player.getStatus(),
                                    "播放状态",
                                    "视频音量" + player.getVolume()
                                    );
                                }
                            }
                        )
                    })
                 }

            })
            return
        },
        //轮询获取状态
        pollingGetStatus(){
            clearInterval(this.pollingTimer);
            this.pollingTimer = setInterval(() => {
                this.$fly.get(`${queryRobotDeviceMapUrl}?regionCode=${this.$vc.getCurrentRegion().code}`).then(res => {
                    let infoRobot = res.data;
                    for(var i = 0; i< this.robotInfor.length;i++){
                        for(var j = 0; j< infoRobot.length;j++){
                            if(this.robotInfor[i].deviceId == infoRobot[j].deviceId){
                                this.robotInfor[i].state = infoRobot[j].state;
                            }
                        }
                    }
                    this.robotStateBtn(this.robotInfor);
                })
            },60000)
        },
        //存储图片
        async createOss(region, bucket) {
            const { oss, stsToken } = this.stsTokenStorage;
            if (stsToken && stsToken.expireTime > new Date().getTime()) {
                return oss;
            }
            let res = await this.$fly.get("/account/staff/ossKey");
            let ossKeyData = res.data;
            const ossConfig = {
                region,
                accessKeyId: ossKeyData.accessKeyId,
                accessKeySecret: ossKeyData.accessKeySecret,
                bucket,
                stsToken: ossKeyData.token,
            },
            aliOss = new OSS(ossConfig);
            this.stsTokenStorage = {
                stsToken: ossKeyData,
                oss: aliOss,
            };
            return aliOss;
            },
        }
}
</script>

<style lang="stylus" scoped>
@import "./common.styl";

.data-item-wrap
    position relative
    @extends .rowFlex
    .data-item-wrap-con
        &:not(:last-of-type)
            margin-right: 50px;

    &:not(:last-of-type)
        margin-right 50px
    .bg
        position relative
        width 580px
        height 450px
        background-image url("../../assets/bigData/three/bg.png")
        .tageText
            position: absolute;
            height: 22px;
            font-size: 16px;
            font-family: Helvetica;
            color: #00FFF4;
            line-height: 22px;
            text-align: center;
            width: 160px;
            left:calc(50% - 70px);
            top:22px;
            .teg
                display: inline-block;
                overflow:hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
                max-width:90px;
                height: 22px;
                line-height: 22px;
                vertical-align: bottom;
        .robotInfo
            display flex
            flex-flow column
            justify-content space-between
            position absolute
            left 40px
            top 70px
            width 210px
            height 94px
            padding 10px 10px
            background rgba(7, 65, 182, 0.2)
            box-shadow inset 0px 0px 8px 0px rgba(0, 255, 255, 0.8)
            font-size 12px
            color #fff
            .electricQuantity
                display flex
                .robotState
                    width 56px
                    margin-left 10px
                    background-color #C4FFF3
                    border-radius 10px
                    text-align center
                    color #09DEB3

            p
                margin-bottom 0
        .photograph
            display flex
            flex-flow column
            justify-content flex-start
            position absolute
            right 36px
            top 70px
            width 100px
            height 335px
            padding 10px
            background rgba(7, 65, 182, 0.2)
            box-shadow inset 0px 0px 8px 0px rgba(0, 255, 255, 0.8)
            img
                width 80px
                height 55px
                border 1px solid #1AC9FF
                &:not(:last-of-type)
                    margin-bottom 10px
    .personal-type-panel
        overflow hidden
        background rgba(7, 65, 182, 0.25)
        box-shadow inset 0 0 50px  0 rgba(0, 255, 255, .2)
        padding 20px
        border-right 1px solid #1ac9ff
        .patrolRouteImg
            display flex
            flex-wrap wrap
            justify-content space-between
            align-content space-between
            height 360px
            .patrolRouteImg_item
                position relative
                width 262px
                height 172px
                background-image url("../../assets/bigData/three/patrolRouteBg.png")
                span
                    color #00FFF4
                    margin-left 18px
                    display block
                    line-height 22px
        .console_Info
            display flex
            .first_col_roBot
                display flex
                flex-flow column
                justify-content space-between
                height 366px
                margin-right 10px
                .roBot_item
                    position relative
                    width 224px
                    height 102px
                    padding 10px 20px
                    background-image url("../../assets/bigData/three/consoleRobotBg.png")
                    color #fff
                    .roBot_item_name
                        display flex
                        align-items center
                        width:200px;
                        .name
                            font-size 26px
                            color #1AC9FF
                            margin-right 10px
                            max-width 105px
                            overflow hidden
                            white-space nowrap
                            text-overflow ellipsis
                        .state
                            min-width 26px
                            padding 0 10px;
                            height 20px
                            font-size 12px
                            line-height 20px
                            border-radius 10px
                            background-color #C4FFF3
                            color #09DEB3
                            text-align center
            .left_line
                display flex
                flex-flow column
                justify-content space-around
                height 366px
                .line_first
                    width 113px
                    height 54px
                    background-image url("../../assets/bigData/three/inflectionline.png")
                .line_second
                    width 96px
                    height 18px
                    background-image url("../../assets/bigData/three/straightline.png")
                .line_third
                    transform rotateX(180deg)
                    width 113px
                    height 54px
                    background-image url("../../assets/bigData/three/inflectionline.png")
            .center
                display flex
                flex-flow column
                justify-content center
                width 150px
                .hollow_circles
                    width 150px
                    height 150px
                    padding 15px
                    background-image url("../../assets/bigData/three/hollowcircle.png")
                    .circle
                        width 120px
                        height 120px
                        line-height 120px
                        font-size 30px
                        background-image url("../../assets/bigData/three/circle.png")
                        text-align center
                        color #1AC9FF
                        .text
                            animation rotate 2s linear 0s infinite
                            cursor pointer
                        .texts
                            animation rotate 2s linear 0s infinite
                            cursor default
                        .textj
                            cursor default
                .hollow_circle
                    width 150px
                    height 150px
                    padding 15px
                    background-image url("../../assets/bigData/three/hollowcircle.png")
                    animation rotate 2s linear 0s infinite
                    .circle
                        width 120px
                        height 120px
                        line-height 120px
                        font-size 30px
                        background-image url("../../assets/bigData/three/circle.png")
                        text-align center
                        color #1AC9FF
                        animation anti-clockwise_rotate 2s linear 0s infinite
                        .text
                            animation rotate 2s linear 0s infinite
                            cursor pointer
                        .texts
                            animation rotate 2s linear 0s infinite
                            cursor default
                        .textj
                            cursor default

            .right_line
                @extends .left_line
                transform rotateY(180deg)
                margin-right 10px

    .patrolRecord
        width 570px
        height 450px
    .patrolRoute
        width 885px
        height 450px
    .console
        width 885px
        height 450px
.swiper_header
    display flex
    color #fff
    div
        text-align center
.swiper-pass-log
    height 300px
    overflow hidden
    .swiper-slide
        height unset
        &.insert
            animation insetPassLog .4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
        &:not(:last-of-type)
            margin-bottom 15px
    ul
        height 40px
        margin-bottom 25px
        list-style none
        font-size 0
        position relative
        overflow hidden
        @extends .rowFlex
        padding 0 10px
        justify-content space-evenly
        //@extends .rowFlex
        //box-shadow inset 0 0 2.212vw 0 rgba(0, 255, 255, .2)
        background linear-gradient(180deg, rgba(39, 247, 225, .2) 0%, rgba(255, 255, 255, 0) 48%, rgba(39, 247, 225, .2) 100%);
        transition transform .4s 40ms
        &:hover
            transform scale(1.06)
        &::before
            position absolute
            left 0
            top 50%
            width 14px
            height 100%
            content " "
            background url("../../assets/bigData/1/zkh.png") no-repeat
            background-size 100% 100%
            transform translateY(-50%)
        &::after
            position absolute
            right 0
            top 50%
            width 14px
            height 100%
            content " "
            background url("../../assets/bigData/1/ykh.png") no-repeat
            background-size 100% 100%
            transform translateY(-50%)
        li
            font-size 12px
            color #fff
            padding 8px  10px
            line-height 1
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
            max-width 8vw
            flex 0 0 auto
            &:not(:last-of-type)
                margin-right 0.5vw
.drone-video {
    width: 90%;
    height: 80%;
    position: absolute;
    bottom:30px;
    left: 31px;
    .ali-player {
        z-index: 0;
        width: 100% !important;
        height: 100% !important;
    }
    }
@keyframes rotate {
    0% {
        transform rotate(0)
    }
    100% {
        transform rotate(360deg)
    }
}
@keyframes anti-clockwise_rotate {
    0% {
    transform rotate(0)
    }
    100% {
    transform rotate(-720deg)
    }
}
.disconnected{
    padding-right: 90px;
    font-size:25px;
    color:white;
    z-index:99;
    position: absolute;
    top:220px;
    width:100%;
    text-align: center;
}
.returnRobot{
    background: url('../../assets/bigData/three/fanhuidaohang.png') no-repeat;
    position: absolute;
    right: -80px;
    bottom:-2px;
    font-size: 16px;
    color: #1AC9FF;
    height: 40px;
    line-height: 38px;
    text-align: center;
    width: 70px;
    background-size:100% 100%;
    cursor:pointer;
}
</style>
